$background-color: #fff;

$primary-color: #1f1b1f;
$secondary-color: #570bad;
$secondary-color-muted: #fcf6fb;

$highlight-color: #570bad1f;

$light-gray: #e8e8e8;
$error-red: #c6157f;

$navbar-height-mobile: 66px;
$navbar-width-desktop: 300px;

$project-colors: (
    purple: #570bad,
    light-purple: #8a37e9,
    dark-purple: #30075f,
    blue: #4895ef,
    light-blue: #6db1ff,
    dark-blue: #0b52a3,
    blue-purple: #3f37c9,
);

@mixin project-colors() {
    @each $name, $color in $project-colors {
        &.bg-#{"" + $name} {
            background-color: $color;
        }
    }
}

$navbar-spacer-desktop: 32px;
$navbar-spacer-mobile: 16px;

$layout-container-spacer-y-desktop: 40px;
$layout-container-spacer-y-mobile: 40px;
$layout-container-spacer-x-desktop: 40px;
$layout-container-spacer-x-mobile: 16px;

$tracker-entry-color-width: 6px;
$tracker-entry-padding-top: 22px;
$tracker-entry-height: 70px;

$tracker-entry-spacer: 10px;