.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    height: 50px;
    background-color: $primary-color;
    border-radius: 10px;
    font: inherit;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    outline: 0;
    border: none;
    width: 100%;

    &.gradient {
        background: #b5179e;
        background: linear-gradient(150deg, rgba(181, 23, 158, 1) 0%, rgba(63, 55, 201, 1) 100%);

        // ITODO: add hover state to this
    }

    &.outline {
        border: 3px solid $primary-color;
        color: $primary-color;
        background-color: #fff;
    }

    &.loading {
        opacity: 0.5;
    }

    &.apple-sign-in,
    &.google-sign-in {
        &:before {
            content: ' ';
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-right: 7px;
        }
    }

    &.apple-sign-in {
        &:before {
            background-image: url('../assets/icon-apple-sign-in.svg');
        }
    }

    &.google-sign-in {
        &:before {
            background-image: url('../assets/icon-google-sign-in.svg');
        }
    }
}

$theme-color: #b5179e;

a {
    &:not(.btn, .nav-link, .logo) {
        color: $theme-color;
        text-decoration: none;
        position: relative;
        transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
        &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 0%;
        height: 2px;
        background-color: $theme-color;
        transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
        &:hover {
            color: darken($theme-color, 10%);
            text-decoration: none;
                &:after {
                    width: 100%;
                    transform: translateX(0%);
                }
            }
        }
  }