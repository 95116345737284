@import './variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');



body {
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  color: $primary-color;
  line-height: 1.5;

  &.secondary-color {
    color: $secondary-color;
  }
  &.light-weight {
    font-weight: 400;
  }
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

strong {
    font-weight: 900;
}

@mixin font-style($size: 16px, $weight: 400, $color: #000) {
    font-family: 'Lato', sans-serif;
    font-size: $size;
    font-weight: $weight;
    color: $color;
}