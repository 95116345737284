.form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 50px;
    cursor: text;
    margin-top: 10px;
    box-sizing: border-box;


    label {
        font-size: 12px;
        font-weight: 700;
        line-height: .8;
        pointer-events: none;
        z-index: 1;
    }

    .form-control {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        padding: 40px 20px 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: #f2f6fa;
        background-clip: padding-box;
        border-radius: 10px;
        border: none;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        box-sizing: border-box;
        outline: 0;

        &:focus {
            outline: 0;
            // box-shadow: 0 0 0 3px $secondary-color;
            box-shadow: inset 0px 0px 0px 3px $primary-color;
        }
    }
}

.form-error {
    margin-top: 5px;
    min-height: 20px;
    text-align: center;
    color: $error-red;
    font-weight: 600;
}