.layout-wrapper {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;

    --navbar-height: calc(#{$navbar-height-mobile} + env(safe-area-inset-bottom));

    nav {
        position: fixed;
        bottom: 0;
        width: 100vw;
        border-top: 1px solid $light-gray;
        height: var(--navbar-height);
        box-sizing: border-box;
        padding: 0;

        z-index: 900;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        background-color: hsla(0,0%,98%,.5);

        .logo {
            margin-bottom: $layout-container-spacer-y-desktop;
            img {
                width: 160px;
                margin: $navbar-spacer-desktop 0 $navbar-spacer-desktop ($navbar-spacer-desktop + ($navbar-spacer-desktop * 0.75));
            }
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        @include media-breakpoint-up(md) {
            position: initial;
            border-top: 0;
            border-right: 1px solid $light-gray;
            width: $navbar-width-desktop;

            height: 100vh;

            padding: $layout-container-spacer-y-desktop 0;
        }

        ol {
            list-style: none;
            list-style-type: none;
            margin: 0;
            padding: 0;

            display: flex;
            justify-content: center;

            @include media-breakpoint-up(md) {
                display: block;
            }

            li {
                flex: 1;
                min-width: 80px;
                max-width: 168px;

                @include media-breakpoint-up(md) {
                    flex: none;
                    min-width: 100%;
                    max-width: 100%;
                }

                .nav-link {
                    display: block;
                    width: 100%;
                    text-align: center;
                    text-decoration: none;
                    color: $primary-color;
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    -webkit-tap-highlight-color: transparent;

                    @include media-breakpoint-down(md) {
                        .nav-link-inner {
                            margin: 5px 18px;
                            padding: 8px;
                            box-sizing: border-box;
                            border-radius: 18px;
                        }
                        &.active {
                            .nav-link-inner {
                                background-color: $highlight-color;
                            }
    
                            color: $secondary-color;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        .nav-link-inner {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }

                        padding: calc($navbar-spacer-desktop * 0.65) 0 calc($navbar-spacer-desktop * 0.65) ($navbar-spacer-desktop * 0.75);
                        margin: calc($navbar-spacer-desktop / 2) $navbar-spacer-desktop;

                        width: calc(100% - #{$navbar-spacer-desktop * 2}); // need to subtract the margin width

                        border-radius: 22px;

                        &.active {
                            color: $secondary-color;
                            background-color: $highlight-color;
                        }
                    }

                    .icon {
                        height: 24px;

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .label {
                        font-size: 0.75rem;

                        @include media-breakpoint-up(md) {
                            margin-left: 14px;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }

    main {
        position: relative;
        box-sizing: border-box;
        padding: $layout-container-spacer-y-mobile $layout-container-spacer-x-mobile;
        height: 100vh;
        width: 100vw;

        /* iOS Safari hack to get the content visible above the bottom bar */
        padding-bottom: calc(var(--navbar-height) + 75px);

        @include media-breakpoint-up(md) {
            padding: $layout-container-spacer-y-desktop $layout-container-spacer-x-desktop;
            width: calc(100vw - #{$navbar-width-desktop});
        }
        overflow-y: scroll;
    }
    
    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
    }
}