.login-wrapper {
    position: relative;
    height: 100vh;
    overflow-y: auto;

    .login-header {
        text-align: center;
        padding: 70px 0;

        @include media-breakpoint-down(lg) {
            padding: 30px 0;
        }

        .login-logo {
            height: 32px;
        }
    }

    .container-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: stretch;
        margin: auto;
        width: 100%;
        max-width: 960px;
        overflow: hidden;
        padding: 0 20px;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            flex-grow: 1;
            align-items: stretch;
            margin: 0;
            border-radius: 0;
            padding: 0;
        }

        .container {
            display: flex;
            flex-basis: 50%;
            flex-grow: 1;
            min-height: 460px;
            max-width: none;
            box-sizing: border-box;
            padding: 60px 80px;

            @include media-breakpoint-down(lg) {
                padding: 30px 20px;
            }

            &.-signup {
                background-color: #FCF6FB;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;

                .banner {
                    margin: auto;
                }

                @include media-breakpoint-down(lg) {
                    border-radius: 0;
                }
            }

            &.-login {
                justify-content: stretch;
                margin-left: -2px;
                box-shadow: inset 0 0 0 2px #FCF6FB;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                @include media-breakpoint-down(lg) {
                    padding-top: 0;
                    box-shadow: none;
                    border-radius: 0;
                }
            }

            .title {
                text-align: center;
                min-width: 100%;
            }

            .container-inner {
                flex-grow: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
                max-width: 320px;
            }
        }
    }
}

.or-separator {
    position: relative;
    margin-top: 10px;
    width: 100%;
    height: 50px;

    line-height: 50px;
    text-align: center;

    color: #696969;
    text-transform: uppercase;
    font-size: 14px;

    &:before,
    &:after {
        position: absolute;
        width: 130px;
        height: 1px;

        top: 24px;

        background-color: #aaa;

        content: '';
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
}


.email-verify-container {
    text-align: center;
    display: flex;
    min-height: 100vh;

    // Center the child element horizontally and vertically within the container
    align-items: center;
    justify-content: center;

    .email-verify-icon {
        width: 180px;
    }

    // define the styles for the .techy-text class
    .techy-text {
        margin-top: 7px;

        // set the font family to a monospace font
        font-family: "Courier New", monospace;
    
        // set the font size to be slightly smaller than the default size
        font-size: 0.9em;
    
        // set the text color to a dark gray
        color: #333;
    
        // set the line height to be slightly larger than the default size
        line-height: 1.2em;
    
        // prevent the text from wrapping onto multiple lines
        white-space: nowrap;
    
        // prevent the text from being hyphenated
        hyphens: none;
    
        // prevent the text from being automatically capitalized
        text-transform: none;
    
        // prevent the text from having automatic punctuation
        text-rendering: optimizeSpeed;
        letter-spacing: normal;
        word-spacing: normal;
        text-indent: 0;
        text-shadow: none;
        display: inline-block;
    
        // set the background color of the .techy-text element to a light gray
        background-color: #eee;

        // add a 1px solid black border to the .techy-text element
        border: 1px solid #000;

        // add a small amount of padding to the .techy-text element
        padding: 8px 12px;
    }
}