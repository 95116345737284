// Importing only bootstrap utilities
@import "../bootstrap/scss/bootstrap-utilities";

@import "./variables";
@import "./typography";

@import "./buttons";
@import "./forms";

@import "./layout";

// Pages
@import "./login";
@import "./tracker";

body {
    margin: 0;
    background-color: $background-color;
    height: 100vh;
    height: -webkit-fill-available;
    overflow: hidden;
}