// ITODO: Need to clean this file up, but good for now.

$tracker-bar-height: 60px;
$tracker-bar-height-mobile: 46px;

.tracker-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }

    .tracker-dropdown {
        flex-grow: 1;
        order: 2;
        min-width: 85%;

        @include media-breakpoint-up(md) {
            order: 1;
            min-width: 200px;
        }

        &:focus {
            outline: 3px solid #570bad;
        }

        &__menu {
            background-color: #fff;
            border-radius: 22px;
            border: 2px solid #e8e8e8;

            box-shadow: none;
        
            &-list {
              margin: 0;
              padding: 0;
              list-style: none;
            }
        }

        .tracker-dropdown__control {
            min-height: $tracker-bar-height;
            border-radius: 22px;
            border-color: #e8e8e8;
            border-width: 2px;
            cursor: text;

            @include media-breakpoint-down(md) {
                margin-top: 22px;
                min-height: $tracker-bar-height-mobile;
            }

            &--is-focused {
                box-shadow: none;
                border-color: #666666;
            }
        }
        &__indicator-separator {
            display: none;
        }
        &__indicator {
            padding-left: 23px;
            padding-right: 23px;
        }
        &__value-container {
            padding-left: 23px;
            padding-right: 23px;
        }
        &__option {
            cursor: pointer;
            display: block;
            font-size: inherit;
            width: 100%;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            background-color: #ffffff;
            color: inherit;
            padding: 0 23px;
            box-sizing: border-box;
            line-height: $tracker-bar-height * 0.9;

            &:first-child {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            &--is-focused {
                background-color: #fff;
            }

            &--is-selected,
            &:active {
                cursor: pointer;
                display: block;
                font-size: inherit;
                width: 100%;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                background-color: rgba(87, 11, 173, 0.1215686275);
                color: #570bad;
                box-sizing: border-box;
            }
        }

        .new-project-button {
            background-color: #fff;
            border: none;
            border-top: 1px solid #e8e8e8;
            padding: 0 10px;
            line-height: $tracker-bar-height;
            height: $tracker-bar-height;
            display: flex;
            justify-content: center;

            display: flex;
            align-items: center;

            width: 100%;
            cursor: pointer;

            color: #000;

            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;

            svg {
                margin-right: 5px;
            }
        }        
    }

    .tracker-time-input-container {
        position: relative;
        margin: 0 0 0 12px;
        order: 3;

        @include media-breakpoint-up(md) {
            order: 2;
            margin: 0 15px;
        }

        .tracker-time-input {
            height: $tracker-bar-height-mobile - 4px;
            line-height: $tracker-bar-height-mobile - 4px;
            border-radius: 0;
            padding: 0;
            margin: 10px 0;
            box-sizing: border-box;
            font-size: 22px;
            border: none;
            outline: none;

            max-width: 112px;
            text-align: right;

            &:focus {
                outline: none;
            }

            @include media-breakpoint-up(md) {
                border-radius: 22px;
                max-width: 192px;
                font-size: 30px;
                height: $tracker-bar-height - 4px;
                line-height: $tracker-bar-height - 4px;
                padding: 0 15px;

                &:focus {
                    outline: 2px solid #111;
                }
            }

            &::placeholder {
                color: #111;
            }
        

        
            /* iOS-specific properties */
            appearance: none;
            box-shadow: none;
        
            /* Android-specific properties */
            -webkit-tap-highlight-color: transparent;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        
            @include media-breakpoint-up(md) {
                font-size: 24px;
                margin: 0;
                max-width: 150px;
            }
        }

        .dropdown-container {
            position: absolute;
            top: 100%;
            right: 0;
            margin-top: 8px;
            padding: 0;
            border: 1px solid #ccc;
            background-color: #fff;
            border-radius: 22px;
            z-index: 100;

            width: 260px;

            .dropdown-item {
                cursor: pointer;
                display: block;
                font-size: inherit;
                width: 100%;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                background-color: #ffffff;
                color: inherit;
                padding: 0 23px;
                box-sizing: border-box;
                line-height: $tracker-bar-height * 0.9;

                &:first-child {
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                &:last-child {
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                }

                &--is-focused {
                    background-color: #fff;
                }

                &--is-selected,
                &:active {
                    cursor: pointer;
                    display: block;
                    font-size: inherit;
                    width: 100%;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    background-color: rgba(87, 11, 173, 0.1215686275);
                    color: #570bad;
                    box-sizing: border-box;
                }
            }

        }
    }

    $shadow-color: rgba(87, 11, 173, 0.1215686275); // set the shadow color and opacity
    $shadow-size: 20px;
    
    // Define the mixin that will create the pulsing box shadow
    @mixin pulse-shadow {
        box-shadow: 0 0 0 $shadow-size $shadow-color;
        animation: pulse 5s ease-in-out infinite;
    }
    
    // Define the keyframes for the pulsing animation
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
        50% {
            box-shadow: 0 0 0 $shadow-size $shadow-color;
        }
        100% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }

    .tracker-button-container {
        order: 1;
        text-align: center;
        flex-basis: 100%;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            order: 3;
            flex-basis: auto;
            margin-bottom: 0;
            margin-left: 10px;
        }
    }

    .tracker-button {
        order: 1;

        width: 120px;
        height: 120px;

        position: relative;
        display: inline-block;
        border-radius: 50%;
        border: none;
        background: linear-gradient(-30deg, #3F37C9, #B5179E);
        cursor: pointer;
        color: #ffffff;

        box-shadow: 0 0 0 15px $shadow-color;

        @include media-breakpoint-up(md) {
            order: 3;

            width: 60px;
            height: 60px;

            box-shadow: 0 0 0 10px $shadow-color;
        }

        svg {
            margin-top: 3px;
            margin-left: 2px;

            width: 40px;
            height: 40px;

            @include media-breakpoint-up(md) {
                width: 24px;
                height: 24px;
            }
        }

        &.is-active {
            @include pulse-shadow;
        }
    }
}

.tracker-entries {
    padding: 30px 0;
    margin-left: -$layout-container-spacer-x-mobile;
    margin-right: -$layout-container-spacer-x-mobile;
    .swipeable-list {
        overflow-y: visible;
        .swipeable-list-item {
            overflow: visible;
            &__content {
                display: block;
                height: 100%;
                align-items: center;
            }
        }
        .swipe-action {
            display: flex;
            width: 100%;
            align-items: stretch;

            .swipe-action-item {
                cursor: pointer;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0 18px;
                box-sizing: border-box;
                user-select: none;
                background-color: $error-red;

                @include font-style(null, null, #fff);
            }
        }
    }
    .swipeable-list-item {
        position: relative;
        height: $tracker-entry-height;
        box-sizing: border-box;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: linear-gradient(to right, transparent, rgba(224, 224, 224, 0.478), transparent);
            z-index: -1;
        }

        &:first-child {
            .tracker-entry-color {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                height: $tracker-entry-height + 3px;
                margin-top: -3px;
            }
        }

        &:last-child {
            .tracker-entry-color {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

                height: $tracker-entry-height + 3px;
                margin-bottom: -3px;
            }
        }

        .tracker-entry-color {
            position: absolute;
            left: $layout-container-spacer-x-mobile;
            width: $tracker-entry-color-width;
            height: $tracker-entry-height;
            background-color: map-get($project-colors, purple);
            @include project-colors();
        }

        .tracker-entry-content {
            padding-left: $layout-container-spacer-x-mobile;
            padding-right: $layout-container-spacer-x-mobile;
            display: flex;
            height: 100%;
            // align-items: center;

            .tracker-entry-date {
                padding-left: $layout-container-spacer-x-mobile + $tracker-entry-color-width;
                color: #3F37C9;
                margin-top: $tracker-entry-padding-top;
                .tracker-entry-date-month {
                    font-size: 12px;
                    text-transform: uppercase;
                    width: 30px;
                    text-align: center;
                    line-height: 10px;
                }
                .tracker-entry-date-day {
                    font-size: 18px;
                    width: 30px;
                    text-align: center;
                    letter-spacing: 2px;
                    line-height: 20px;
                }
            }

            .tracker-entry-project {
                padding-left: $tracker-entry-spacer;
                overflow: hidden;

                white-space: nowrap;
                overflow: hidden;
                position: relative; /* set the position to relative */
                padding-right: 40px; /* adjust the padding to increase/decrease the length of the fade-out */                  
                margin-top: $tracker-entry-padding-top;

                &:after {
                    content: '';
                    position: absolute; /* set the position to absolute */
                    top: 0;
                    right: 0;
                    width: 40px; /* set the width to match the padding-right value */
                    height: 100%;
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
                }

                .tracker-entry-description {
                    font-size: 16px;
                    line-height: 11px;
                    color: #6A6565;
                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }
                }

                .tracker-entry-project-name {
                    padding-top: 7px;
                    font-size: 11px;
                    line-height: 11px;
                    color: #B5B5B5;
                    @include media-breakpoint-up(md) {
                        font-size: 14px;
                    }
                }
            }
            .tracker-entry-duration {
                margin-top: $tracker-entry-padding-top;
                margin-left: auto;
                font-size: 14px;
                line-height: 11px;
                letter-spacing: 1.5px;
                color: #6A6565;
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                }
            }
        }
    }
}

@import './projectdropdown';