.task-dropdown-container {
    position: relative;
    flex-grow: 1;
    order: 2;
    flex: 1;

    @include media-breakpoint-up(md) {
        order: 1;
        min-width: 200px;
    }
    .task-control {
        display: flex;
        min-height: $tracker-bar-height;
        border-radius: 22px;
        border-color: #e8e8e8;
        border-width: 2px;
        border-style: solid;
        box-sizing: border-box;

        @include media-breakpoint-down(md) {
            min-height: $tracker-bar-height-mobile;
        }

        &.is-focused {
            box-shadow: none;
            border-color: #111;

            .project-control {
                border-color: #111;
            }
        }
        .task-input {
            height: $tracker-bar-height-mobile - 2px;
            line-height: $tracker-bar-height-mobile - 2px;
            border-radius: 22px;
            padding: 0 $navbar-spacer-mobile;
            box-sizing: border-box;
            border: none;
            outline: none;
            width: 100%;
            font-size: 16px;

            appearance: none;
            -webkit-border-radius: 22px;
            box-shadow: none;

            -webkit-tap-highlight-color: transparent;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            @include media-breakpoint-up(sm) {
                padding: 0 $navbar-spacer-desktop;
                height: $tracker-bar-height - 2px;
                line-height: $tracker-bar-height - 2px;
            }
        }
    }
    .suggestions-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 8px;
        padding: 0;
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 22px;
        z-index: 100;
        box-sizing: border-box;

        min-width: 60%;

        .suggestions-title {
            padding: 14px 23px 8px 23px;
            font-size: 14px;
            font-weight: 600;
            color: #111;
        }

        .suggestion-item {
            cursor: pointer;
            display: block;
            font-size: inherit;
            width: 100%;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            background-color: #ffffff;
            color: inherit;
            padding: 0 23px;
            box-sizing: border-box;
            line-height: $tracker-bar-height * 0.9;

            &:first-child {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            &:last-child {
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            &--is-focused {
                background-color: #fff;
            }

            &--is-selected,
            &:active {
                cursor: pointer;
                display: block;
                font-size: inherit;
                width: 100%;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                background-color: rgba(87, 11, 173, 0.1215686275);
                color: #570bad;
                box-sizing: border-box;
            }
        }
    }
    .project-control {
        position: absolute;
        right: 2px;
        padding: 0 $navbar-spacer-mobile;
        height: $tracker-bar-height - 2px;
        line-height: $tracker-bar-height - 2px;
        cursor: pointer;
        border-left: 2px solid #e8e8e8;
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
		font-size: 14px;
        color: #999999;

        display: none;

        @include media-breakpoint-up(sm) {
            padding: 0 $navbar-spacer-desktop;
            display: block;
        }

        &:hover {
            color: #111;
        }
    }
}

.dropdown {
	position: absolute;
	top: 100%;
	margin-top: 8px;
	padding: 0;
	border: 1px solid #ccc;
	background-color: #fff;
	border-radius: 22px;
	z-index: 100;
	box-sizing: border-box;

	min-width: 240px;
	// min-width: 50%;

	&.dropdown-left {
		left: 0;
	}

	&.dropdown-right {
		right: 0;
	}

	.dropdown-title {
		padding: 14px 23px 8px 23px;
		font-size: 14px;
		font-weight: 600;
		color: #111;
	}

	.dropdown-item {
		cursor: pointer;
		display: block;
		font-size: inherit;
		width: 100%;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-tap-highlight-color: rgba(158, 80, 80, 0);
		background-color: #ffffff;
		color: inherit;
		padding: 0 23px;
		box-sizing: border-box;
		line-height: $tracker-bar-height * 0.9;

		&:first-child {
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
		}

		&:last-child {
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px;
		}

		&:active {
			cursor: pointer;
			display: block;
			font-size: inherit;
			width: 100%;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			background-color: rgba(87, 11, 173, 0.1215686275);
			color: #570bad;
			box-sizing: border-box;
		}
	}
}